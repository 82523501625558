
import { debounceProcess } from "@/helpers/debounce";
import { useBranch } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { Vue, Component, Mixins } from "vue-property-decorator";
import MNotificationVue from "./MNotification.vue";

/**
 * @deprecated
 */
@Component
export default class MMasterBranch extends Mixins(MNotificationVue) {
  loadingBranch = false;

  branchOptions: Option<DataWarehouseBranch>[] = [];

  created(): void {
    this.onSearchBranch = debounceProcess(this.onSearchBranch);
  }

  async fetchBranch(params: RequestQueryParams): Promise<void> {
    const { findAll, toOptions } = useBranch();
    try {
      this.loadingBranch = true;
      const res = await findAll(params);
      this.branchOptions = toOptions(res.data);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loadingBranch = false;
    }
  }

  onSearchBranch(search = ""): void {
    const { searchBy } = useBranch();
    const params = new RequestQueryParams();
    params.search = searchBy({ name: search });

    this.fetchBranch(params);
  }
}
